const enter = ['ease-out', 'duration-300']
const enterFrom = ['opacity-0', 'translate-y-4', 'sm:translate-y-0', 'sm:scale-95']
const enterTo = ['opacity-100', 'translate-y-0', 'sm:scale-100']
const leave = ['ease-in', 'duration-200']
const leaveFrom = ['opacity-100', 'translate-y-0', 'sm:scale-100']
const leaveTo = ['opacity-0', 'translate-y-4', 'sm:translate-y-0', 'sm:scale-95']

const $modal = document.getElementById('modal')
const $modalPanel = document.getElementById('modal-panel')

function donateNow() {
	$modal.classList.remove('hidden')
	$modalPanel.classList.add(...enterFrom)
	setTimeout(() => {
		$modalPanel.classList.add(...enter)
		$modalPanel.classList.remove(...enterFrom)
		$modalPanel.classList.add(...enterTo)
		setTimeout(() => {
			$modalPanel.classList.remove(...enterTo.concat(enter))
		}, 300)
	}, 300)
}

function hideModal() {
	$modalPanel.classList.add(...leaveFrom)
	setTimeout(() => {
		$modalPanel.classList.add(...leave)
		$modalPanel.classList.remove(...leaveFrom)
		$modalPanel.classList.add(...leaveTo)
		setTimeout(() => {
			$modalPanel.classList.remove(...leaveTo.concat(leave))
			$modal.classList.add('hidden')
		}, 200)
	}, 200)
}
